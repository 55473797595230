* {
    margin: 0;
    padding: 0;
  }
  
  .node-title {
    dx: -200;
    fill: green;
  }
  
  .node-label {
  }
  
  .company {
    fill: red;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    background: #cfe4ff;
    padding: 0.75em;
    border-radius: 5px;
    border: 1px solid;
    text-align: left;
    font-family: arial;
  }
  
  .card--is-company {
    background: #fcfcca;
  }
  
  .card--has-children {
    padding-bottom: 0;
  }
  
  h2 {
    font-size: 14px;
  }
  
  p {
    font-size: 12px;
  }
  
  .toggle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    border-radius: 50%;
    background: white;
    width: 30px;
    height: 30px;
    transform: translateY(50%);
    border: 1px solid;
    margin: 0 auto;
  }
  
  .toggle svg {
    width: 12px;
    height: 12px;
  }
  
  .colors {
    padding: 1em;
  }
  
  .clinic,
  .company {
    display: inline-flex;
    align-content: center;
    margin-right: 2em;
  }
  
  .clinic::before,
  .company::before {
    display: inline-flex;
    width: 1em;
    height: 1em;
    content: "";
    border: 1px solid green;
    border-radius: 2px;
    margin-right: 0.5em;
  }
  
  .clinic::before {
    background: #cfe4ff;
  }
  
  .company::before {
    background: #fcfcca;
  }
  